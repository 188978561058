<template>
  <div>
    <ul class = "listBox">
      <li @click="toDetail(v.id, v.navigationId)" :key = "index" v-for = "v,index in dataList">
        <p>{{isEn ? v.enTitle : v.cnTitle }}</p>
        <div>
          <span class = "r">{{isEn ? 'readCounts' : '点击次数'}}：{{v.readCounts}}</span>
          <span>{{v[dateVar] | formatDate}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

  export default {
    props:{
      dataList:{
        type:Array
      },
      dateVar:{
        type:String
      }
    },
    data() {
      return {
        isEn:false
      }
    },
    methods: {
      toDetail(id, navId) {
          const articleId = encodeURIComponent(btoa(id));
          const navigationId = encodeURIComponent(btoa(navId));
          this.$router.push({
              name: 'NewsDetail',
              params: {
                  id: articleId
              },
              query: {
                  n: navigationId
              }
          })
      }
    },
    watch: {
        "$i18n.locale": function(val) {
            this.isEn = val === 'en' ? true : false;
        }
    },
    destroyed () {
      //document.body.style.overflow = '';
    },
    mounted() {
      this.$i18n.locale === 'en' ? this.isEn = true : this.isEn = false;
    }
  };
</script>

<style lang="less" scoped>
.fontFn (@px, @attr: font-size) {
    @rem: (@px / 37.5);
    @{attr}: ~"@{rem}rem";
}

.listBox{display: none}

@media screen and (max-width: 750px) {
  .listBox{
    display: block;
    li{
      background-color: #f3f3f3;
      margin-bottom:4%;
      border-radius: 8px;
      padding: 6%;
      padding-right: 12%;
      position: relative;
      p{
        .fontFn(14);
        .fontFn(22,line-height);
        color:#685f75;
        font-weight: bold;
        padding-bottom: 4%;
      }
      div{
        span{
          .fontFn(12);
        }
        .r{float: right;}
      }
      &::after{
        content: '';
        display: block;
        position: absolute;
        .fontFn(16,width);
        .fontFn(16,height);
        top:50%;
        right:6%;
        border-top:2px solid #B9B9B9;
        border-right:2px solid #B9B9B9;
        transform: rotate(45deg) translateY(-50%);
        -webkit-transform: rotate(45deg) translateY(-50%);
        -moz-transform: rotate(45deg) translateY(-50%);
        -ms-transform: rotate(45deg) translateY(-50%);
        -o-transform: rotate(45deg) translateY(-50%);
      }
    }
  }
}

</style>