<template>
    <div id="about-subpage">
        <div class="img">
            <img :src="require('@/assets/images/about/subpage_banner.png')" alt="">
            <img :src="require('@/assets/images/about/about-bg_01.png')" class="bg-bottom" alt="">
        </div>
        <div class="subpage-navs">
            <div class="breadcrumbs">
                <el-breadcrumb>
                    <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="index" :to="item.href">
                        {{ item.text }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <ul class="navs">
                <li v-for="(item,index) in navs" :key="index" :class="activedIndex === index ? 'actived' : ''">
                    <router-link :to="item.templateUrl">{{ $i18n.locale === 'en' ? item.enName : item.cnName }}</router-link>
                </li>
            </ul>
        </div>
        <div class="content">
            <div class="company-info" v-if="$route.params.page === 'introduction' && detail">
                <div class="info">
                    <div class="lf">
                        <h2>{{ isEn ? detail.enTitle : detail.cnTitle }}</h2>
                        <p>{{ isEn ? detail.enContentSummary : detail.cnContentSummary }}</p>
                    </div>
                    <div class="rf">
                        <img :src="staticPath + (isEn ? detail.enLogo : detail.cnLogo)" alt="">
                    </div>
                </div>
                <ul class="pics" v-if="detail.files.length > 0">
                    <li v-for="(item, index) in detail.files" :key="index">
                        <img :src="staticPath + item.fileUrl" alt="">
                        <p>{{ isEn ? item.fileEnName : item.fileCnName }}</p>
                    </li>
                </ul>
            </div>

            <div class="honor" v-if="$route.params.page === 'honor' && detail">
                <div class="honor-list">
                    <swiper ref="mySwiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in detail.files" :key="index">
                            <div class="pic">
                                <img :src="staticPath + item.fileUrl" alt />
                            </div>
                            <p style="color: #333;">{{ isEn ? item.fileEnName : item.fileCnName }}</p>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>
            </div>

            <div class="development" v-if="$route.params.page === 'development' && dataList">
                <div class="process" v-if="dataList.length > 0">
                    <div class="time-line" ref="timeline">
                        <ul :style="{width: calcTimelineLength}">
                            <li @click="chooseYear(index)" v-for="(item, index) in dataList" :key="index">
                                <strong :class="processTimelineIndex === index ? 'actived' : ''">{{ isEn ? item.enTitle : item.cnTitle }}</strong>
                                <div :class="['time-point', processTimelineIndex === index ? 'time-point-actived' : '']"></div>
                            </li>
                        </ul>
                    </div>
                    <div class="process-content">
                        <div class="lf">
                            <img :src="staticPath + (isEn ? dataList[processTimelineIndex].enLogo : dataList[processTimelineIndex].cnLogo)" alt="">
                        </div>

                        <div class="rf">
                            <h1>{{ isEn ? dataList[processTimelineIndex].enTitle : dataList[processTimelineIndex].cnTitle }}</h1>
                            <span class="point"></span>
                            <p>{{ (isEn ? dataList[processTimelineIndex].cnContentSummary : dataList[processTimelineIndex].cnContentSummary) }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="college" v-if="$route.params.page === 'college'">
                <div class="lf">
                    <img :src="require('@/assets/images/about/windmill.png')" alt="">
                </div>
                <div class="rf">
                    <div class="btns" v-if="collegeBtns">
                        <button :class="['standard', collegeBtnIndex === index ? 'actived' : '']"
                                @click="changeCollegeList(item.id, index)"
                                v-for="(item, index) in collegeBtns"
                                :key="index"
                        >{{ isEn ? item.enName : item.cnName }}</button>
                    </div>
                    <div class="list" v-if="dataList.length > 0">
                        <ComponentCardListH5 :dataList="dataList" dateVar = "updateTime"/>
                        <el-table
                                :data="dataList"
                                style="width: 100%">
                            <el-table-column
                                    show-overflow-tooltip
                                    :label="isEn ? collegeBtns[collegeBtnIndex].enName : collegeBtns[collegeBtnIndex].cnName"
                                    :prop="isEn ? 'enTitle' : 'cnTitle'">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px; cursor: pointer;" @click="toDetail(scope.row)">{{ isEn ? scope.row.enTitle : scope.row.cnTitle }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="180"
                                    :label="isEn ? 'updateTime' : '发布时间'"
                                    prop="updateTime"
                                    align="center">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px">{{ scope.row.updateTime | formatDate }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="140"
                                    :label="isEn ? 'readCounts' : '点击次数'"
                                    prop="readCounts"
                                    align="center">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px">{{ scope.row.readCounts }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="pagination">
                            <el-pagination
                                    background
                                    layout="prev, pager, next"
                                    @current-change="getSubpageList"
                                    :current-page.sync="pageCurrent"
                                    :page-size="pageSize"
                                    :total="total">
                            </el-pagination>
                        </div>
                        <!--<ul>
                            <li v-for="(item, index) in dataList" :key="index">
                                <div class="name">
                                    <i class="icon icon-topic"></i>
                                    <router-link to="">{{ isEn ? item.enTitle : item.cnTitle }}</router-link>
                                </div>
                                <div class="right-part">
                                    <div class="date">
                                        <i class="icon icon-clock"></i>{{ item.updateTime | formatDate }}
                                    </div>
                                    <div class="views">
                                        <i class="icon icon-views"></i>{{ item.readCounts }}
                                    </div>
                                </div>
                            </li>
                        </ul>-->
                    </div>
                    <div class="empty" v-else>
                        <img :src="require('@/assets/images/icon_empty2.png')" alt="">
                        <p>暂无数据</p>
                    </div>
                </div>
            </div>

            <div class="text" v-if="detail && $route.params.page === 'certificates' || $route.params.page === 'strategic'">
<!--                <img :src="detail ? (staticPath + (this.isEn ? detail.enLogo : detail.cnLogo)) : ''" alt="" v-if="detail.enLogo || detail.cnLogo">-->
                <p v-html="this.isEn ? detail.enContent : detail.cnContent"></p>
            </div>

            <div class="table-list" v-if="dataList && $route.params.page === 'activity' || $route.params.page === 'communication'">
                <div class="lf">
                    <img :src="require('@/assets/images/about/windmill.png')" alt="">
                </div>
                <div class="rf">
                    <div class="list">
                        <ComponentCardListH5 :dataList="dataList" dateVar = "createTime"/>
                        <el-table
                                :data="dataList"
                                style="width: 100%">
                            <el-table-column
                                    :prop="isEn ? 'enTitle' : 'cnTitle'"
                                    :label="$route.params.page === 'activity' ? '员工活动' : $route.params.page === 'communication' ? '交流活动' : ''">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px; cursor: pointer;" @click="toDetail(scope.row)">{{ isEn ? scope.row.enTitle : scope.row.cnTitle }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    :label="isEn ? 'updateTime' : '发布时间'"
                                    width="180">
                                <template slot-scope="scope">
                                    {{ scope.row.createTime | formatDate }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="140"
                                    prop="readCounts"
                                    :label="isEn ? 'readCounts' : '点击次数'">
                            </el-table-column>
                        </el-table>
                        <div class="pagination">
                            <el-pagination
                                    background
                                    layout="prev, pager, next"
                                    @current-change="getSubpageList"
                                    :current-page.sync="pageCurrent"
                                    :page-size="pageSize"
                                    :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import "swiper/dist/css/swiper.css";
    import { swiper, swiperSlide } from "vue-awesome-swiper";
    import {urls, staticPath} from '@/lib/common'
    import ComponentCardListH5 from '@/components/ComponentCardListH5'
    export default {
        name: "AboutSubpage",
        components: {
            swiper,
            swiperSlide,
            ComponentCardListH5
        },
        data() {
            return {
                swiperOptions: {
                    slidesPerView: document.body.clientWidth > 750 ? 3 : 1,
                    slidesPerColumn: document.body.clientWidth > 750 ? 2 : 1,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                },
                staticPath: '',
                list: [],
                type: 0,
                page: 1,
                pageCurrent: 1,
                pageSize: 10,
                total: 0,
                breadcrumbs: [
                    {
                        text: "home",
                        pathName: 'Home',
                        disabled: false,
                        href: '/'
                    }, {
                        text: "about",
                        pathName: 'About',
                        disabled: false,
                        href: '/about'
                    }, {
                        text: "introduction",
                        pathName: 'Introduction',
                        disabled: true,
                        href: ''
                    }
                ],
                navs: [],
                activedIndex: 0,
                isEn: false,
                detail: '',
                hasArticle: false, // 是否含有文章, 列表类型专用
                navType: 0, // 0:单页, 1:列表, 2:外链, 3:表单提交
                navId: '',
                dataList: [],
                collegeBtns: [],
                collegeBtnIndex: 0,
                processTimelineIndex: 0,
                stepWidth: 0,

            };
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.$swiper;
            },
            calcTimelineLength() {
                if(document.body.clientWidth > 750)
                    return this.dataList.length * 15 + 'vw';
                else
                    return this.dataList.length * 25 + 6 + '%';
            }
        },
        watch: { // watch 中不能用箭头函数，不然this指向出问题
            "$route.params.page": function(val) {
                this.matchNavs(val);
            },
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        methods: {
            matchNavs(name) {
                this.navs.map((item, index) => {
                    if (item.templateUrl.indexOf(name) !== -1) {
                        this.activedIndex = index;
                        this.hasArticle = item.hasArticle;
                        this.navType = item.type;
                        this.navId = item.id;
                        this.total = 0;
                        this.pageCurrent = 1;
                        this.getBreadcrumbs(item.id);
                        if (this.navType === 1) {
                            if (this.collegeBtns.length > 0 && name === 'college') {
                                this.changeCollegeList(this.collegeBtns[this.collegeBtnIndex].id)
                            } else {
                                this.getSubpageList(item.id);
                            }
                        } else {
                            this.getContent(item.id)
                        }
                        return
                    }
                })
            },
            changeCollegeList(id, index) {
                if (index !== undefined && index !== null && index !== '') this.collegeBtnIndex = index;
                this.total = 0;
                this.pageCurrent = 1;
                this.getSubpageList(id)
            },
            chooseYear(index) { // 发展历程-选择年份
                const timeline = this.$refs.timeline;
                const currPosition = timeline.children[0].children[index].offsetLeft;
                const liWidth = timeline.children[0].children[0].offsetWidth;
                this.processTimelineIndex = index;
                if (index >= 5) {
                    let timer = setInterval(() => {
                        if (this.stepWidth >= currPosition - liWidth / 2) {
                            clearInterval(timer);
                            timer = null;
                        } else {
                            timeline.scrollTo(this.stepWidth+=(liWidth/10), 0)
                        }
                    }, 10)
                } else {
                    let timer = setInterval(() => {
                        if (this.stepWidth + liWidth / 2 < currPosition - liWidth / 2) {
                            clearInterval(timer);
                            timer = null;
                        } else {
                            timeline.scrollTo(this.stepWidth-=(liWidth/10), 0)
                        }
                    }, 10)
                }
            },
            getBreadcrumbs(id) { // 获取面包屑导航列表
                this.$http.get(urls.getBreadcrumb + id).then(res => {
                    if (res.data.status === 200) {
                        const isEn = this.$i18n.locale === 'en' ? true : false;
                        const result = res.data.data;
                        const homePage = {
                            text: isEn ? 'Home' : '首页',
                            pathName: 'Home',
                            disabled: false,
                            href: '/'
                        }
                        this.breadcrumbs = [
                            homePage,
                            {
                                text: isEn ? result[0].enName : result[0].cnName,
                                pathName: result[0].templateUrl,
                                disabled: false,
                                href: result[0].templateUrl
                            }, {
                                text: isEn ? result[1].enName : result[1].cnName,
                                pathName: result[1].templateUrl,
                                disabled: true,
                                href: ''
                            }
                        ]
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.warning(res.data.message)
                    }
                })
            },
            getInformations(id) {
                this.$http.get(urls.getSubNavs + id).then(res => {
                    if (res.data.status === 200) {
                        this.navs = res.data.data;
                        const navName = this.$route.params.page;
                        for (var i in this.navs) {
                            if (this.navs[i].templateUrl.indexOf('college') !== -1) {
                                this.collegeBtns = this.navs[i].data;
                                break;
                            }
                        }
                        this.matchNavs(navName);
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getContent(id) {
                this.$http.get(urls.getContent + id).then(res => {
                    if (res.data.status === 200) {
                        this.detail = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getSubpageList(id) {
                const params = {
                    pageSize: this.pageSize,
                    pageCurrent: this.pageCurrent
                }
                if (id) params.navigationId = id;
                switch(this.$route.params.page) {
                    case 'college':
                        params.navigationId = this.collegeBtns[this.collegeBtnIndex].id
                        break;
                    case 'activity':
                        params.navigationId = this.navId
                        break;
                    case 'communication':
                        params.navigationId = this.navId
                        break;
                    default:
                        break;
                }
                console.log('xxxx', params);
                this.$http.get(urls.getSubpageList, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.dataList = res.data.data.records;
                        this.total = res.data.data.total;
                        if (this.$route.params.page === 'development') {
                            var sortNumber = (a,b) => {
                                return (this.isEn ? b.enTitle - a.enTitle : b.cnTitle - a.cnTitle)
                            }
                            this.dataList.sort(sortNumber)
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            toDetail(item) {
                if (item.redirectUrl.indexOf('http') !== -1) {
                    window.open(item.redirectUrl);
                } else {
                    const articleId = encodeURIComponent(btoa(item.id));
                    const navigationId = encodeURIComponent(btoa(item.navigationId));
                    this.$router.push({
                        name: 'NewsDetail',
                        params: {
                            id: articleId
                        },
                        query: {
                            n: navigationId
                        }
                    })
                }
            }
        },
        created() {
            this.staticPath = staticPath;
        },
        mounted() {
            const contId = window.atob(decodeURIComponent(this.$route.query.n));
            this.getInformations(contId);
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    @pointWidth: 13vw;
    #about-subpage {
        >.img {
            position: relative;
            .bg-bottom {
                position:absolute;
                bottom: 0;
                left: 0;
            }
        }
        .subpage-navs {
            margin: 0 auto;
            color: #7C7FA2;
            font-size: 1.25vw;
            width: 100%;
            background: url("../../../assets/images/about/about-bg_02.jpg") no-repeat center center;
            background-size: 100% 100%;
            @{deep} .el-breadcrumb{
                font-size: 18px;
                .el-breadcrumb__inner a, 
                .el-breadcrumb__inner.is-link{
                    color:#625F6F;
                    font-weight: normal;
                }
            }
            .breadcrumbs {
                width: 1400px;
                margin:0 auto;
                .link {
                    padding: 0 5px;
                }
            }
            .navs {
                width: 1400px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 2vw auto 4vw;
                li {
                    width: 148px;
                    height: 64px;
                    text-align: center;
                    line-height: 64px;
                    position: relative;

                    a{
                        font-size: 18px;
                        color:#7C7FA2;
                    }

                    &:not(:last-child)::after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        right: -10%;
                        height: 30px;
                        margin-top:-15px;
                        border-right: 1px solid #EAEAEA;
                    }
                    &:last-child {
                        border: none;
                    }
                    &.actived {
                        background: linear-gradient(to bottom, #FF4B7E, #D93C46);
                        border-radius: 6px;
                        box-shadow: 0 0.52vw 1vw rgba(217, 60, 70, 0.8);
                        &::after {
                            border-right-color: #EAEAEA;
                        }
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .content {
            padding-bottom: 4vw;
            .company-info {
                font-size: 1.25vw;
                width: 1300px;
                margin: 0 auto;
                .info {
                    .lf {
                        float: left;
                        width: 55%;
                        h2 {
                            color: #353968;
                            font-size: 30px;
                        }
                        >p {
                            font-size: 18px;
                            line-height: 50px;
                            color: #343750;
                            margin: 3vw 0;
                        }
                    }
                    .rf {
                        float: right;
                        width: 40%;
                        img {
                            position: relative;
                            top: 2vw;
                            left: 0;
                        }
                    }
                    &::after {
                        content: '';
                        display: block;
                        clear: both;
                    }
                }
                .pics {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 4vw;
                    li {
                        text-align: center;
                        color: #353968;
                        margin-right: 2vw;
                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                        p {
                            margin-top: 16px;
                            font-size: 18px;
                            font-weight: bold;
                        }
                    }
                }
            }
            .honor {
                width: 76vw;
                margin: 2vw auto 0;
                >h2 {
                    color: #fff;
                }
                .honor-list {
                    font-size: 1.458vw;
                    text-align: center;
                    color: #fff;
                    .pic {
                        &::before,
                        &::after {
                            content: '';
                            display: inline-block;
                            width: 2.7vw;
                            height: 6.5vw;
                            position: relative;
                            top: -0.5vw;
                            left: 0.8vw;
                            background: url('../../../assets/images/about/icon_wheat_left.png') no-repeat center center;
                            background-size: 100%;
                        }
                        &::after {
                            left: -0.8vw;
                            background-image: url('../../../assets/images/about/icon_wheat_right.png');
                        }
                        img {
                            width: 12.5vw;
                            margin: 0 2vw;
                        }
                    }
                    p {
                        width: 14vw;
                        font-size: 18px;
                        margin: 1vw auto 0;
                    }
                    .swiper-container {
                        padding: 4vw 1.85vw !important;
                    }
                    .swiper-slide:nth-child(even) {
                        margin-top: 4vw !important;
                    }
                    .swiper-button-next {
                        width: 1.3vw;
                        height: 2.29vw;
                        background-image: url('../../../assets/images/about/icon_arrow_right.png');
                        background-size: 100%;
                    }
                    .swiper-button-prev {
                        width: 1.3vw;
                        height: 2.29vw;
                        background-image: url('../../../assets/images/about/icon_arrow_left.png');
                        background-size: 100%;
                    }
                    .swiper-pagination {
                        bottom: 0;
                    }
                }
            }
            .process {
                .process-content {
                    width: 60vw;
                    margin: 4vw auto 0;
                    display: flex;
                    justify-content: flex-start;
                    .lf {
                        width: 30vw;
                        height: 30vw;
                        box-sizing: border-box;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .rf {
                        width: 30vw;
                        height: 30vw;
                        background: #1BB2FF;
                        box-sizing: border-box;
                        padding: 4vw 2vw 4vw 4vw;
                        color: #fff;
                        h1 {
                            font-size: 96px;
                        }
                        .point {
                            display: inline-block;
                            width: 32px;
                            height: 32px;
                            background: #fff;
                            border-radius: 50%;
                            margin: 0.5vw 0;
                        }
                        p {
                            font-size: 18px;
                            line-height: 2.2vw;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 6;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .time-line {
                    padding: 2vw 0;
                    overflow-x: scroll;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    ul {
                        margin-left: @pointWidth;
                        &::after {
                            content: '';
                            display: block;
                            clear: both;
                        }
                        li {
                            float: left;
                            text-align: center;
                            width: @pointWidth;
                            padding-top: 5vw;
                            position: relative;
                            strong {
                                font-size: 48px;
                                color: #8ED9FF;
                                position: absolute;
                                left: 1.5em;
                                top: 0;
                                &.actived {
                                    left: 0.5em;
                                    top: -0.35em;
                                    font-size: 56px;
                                    color: #1BB2FF;
                                }
                            }
                            .time-point {
                                width: 100%;
                                height: 0.2vw;
                                background: #8ED9FF;
                                position: relative;
                                cursor: pointer;
                                &::after {
                                    content: '';
                                    display: inline-block;
                                    width: 2.7vw;
                                    height: 2.7vw;
                                    background: #8ED9FF;
                                    border-radius: 50%;
                                    position: absolute;
                                    left: 50%;
                                    margin-left: -1.35vw;
                                    top: -1.35vw;
                                }
                            }
                            .time-point-actived {
                                &::after {
                                    background: #1BB2FF;
                                }
                            }
                        }
                    }
                }
            }
            .text {
                width: 1300px;
                margin: 0 auto 5%;
                font-size: 16px;    
                line-height: 24px;        
            }
            .college,
            .table-list {
                display: flex;
                font-size: 1.25vw;
                color: #5A5A5A;
                padding-bottom: 5vw;
                >.lf {
                    flex: 1;
                    img {
                        width: 580px;
                    }
                }
                >.rf {
                    flex: 2;
                    .types {
                        margin-bottom: 1vw;
                        button {
                            float: left;
                            font-size: 1.25vw;
                            padding: 0.78vw 2.8125vw;
                            background: #fefefe;
                            color: #5A5A5A;
                            border: 1px solid #dbdbdb;
                            outline: none;
                            margin-right: 1.25vw;
                            transition: all .3s linear;
                            &.actived {
                                color: #fff;
                                background: #02A5E6;
                                border-color: #02A5E6;
                            }
                        }
                        &::after {
                            content: '';
                            display: block;
                            clear: both;
                        }
                    }
                    .btns {
                        button {
                            margin-right: 20px;
                            border: 1px solid #ddd;
                            height: 40px;
                            line-height: 40px;
                            outline: none;
                            color: #5A5A5A;
                            font-size: 18px;
                            transition: all .3s linear;
                            width: 100px;
                            border-radius: 5px;
                            &.actived {
                                background-color: #02A5E6;
                                border-color: #02A5E6;
                                color: #fff;
                            }
                        }
                    }
                    @{deep} .el-table tr{
                        font-size: 14px;
                        height: 50px;
                        .cell{
                            line-height: 30px;
                        }
                    }
                }
                .list {
                    margin-top: 20px;
                    width: 86%;
                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #E8E8E8;
                        padding: 1vw 0;
                        width: 80%;
                        a {
                            color: #5A5A5A;
                        }
                        .right-part {
                            width: 40%;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                        }
                        .icon {
                            display: inline-block;
                            width: 1.25vw;
                            height: 1.25vw;
                            background: url("../../../assets/images/about/icon_order.png") no-repeat center center;
                            background-size: 100%;
                            margin-right: 0.52vw;
                            position: relative;
                            top: .1vw;
                        }
                        .icon-clock {
                            background-image: url("../../../assets/images/about/icon_clock.png");
                        }
                        .icon-views {
                            background-image: url("../../../assets/images/about/icon_views.png");
                        }
                    }
                    .pagination {
                        margin: 2vw 0;
                        text-align: right;
                        @{deep} .v-pagination {
                            justify-content: flex-end;
                        }
                        @{deep} .v-pagination__item {
                            width: 1.77vw;
                            height: 1.77vw;
                            font-size: 1.2vw;
                            margin: 0.5vw;
                        }
                        @{deep} .v-pagination__navigation {
                            width: 1.67vw;
                            height: 1.67vw;
                        }
                        @{deep} .v-pagination__item--active {
                            background: #02A5E6;
                            color: #fff;
                        }
                    }
                }
                .empty {
                    text-align: center;
                    margin-top: 10%;
                    img {
                        width: 30%;
                    }
                    p {
                        margin-top: 1vw;
                    }
                }
            }
            .table-list {
                margin: 4vw auto 0;
            }
        }
    }
    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #about-subpage {
            .subpage-navs{
                width: 100%;
                padding: 4%;
                @{deep} .el-breadcrumb{
                    .fontFn(12);
                }
                .breadcrumbs{
                    width: 100%;
                }
                .navs{
                    margin:0;
                    padding:4% 0;
                    display: block;
                    width: 100%;
                    li{
                        display: inline-block;
                        vertical-align: top;
                        width: 33.3%;
                        .fontFn(30,line-height);
                        .fontFn(30,height);
                        a{
                            .fontFn(12);
                        }
                        &:not(:last-child)::after{
                            content:none;
                        }
                    }
                }
            }

            .content{
                .company-info{
                    width: 100%;
                    padding: 0 4%;
                    .info{
                        .lf{
                            width: 100%;
                            h2{
                                .fontFn(16);
                            }
                            &>p{
                                .fontFn(12);
                                .fontFn(20,line-height);
                            }
                        }
                        .rf{display: none}
                    }
                    .pics{
                        display: block;
                        li{
                            p{
                                .fontFn(12);
                                margin-bottom: 6%;
                            }
                        }
                    }   
                }
                .text{
                    width: 100%;
                    padding: 0 6%;
                    .fontFn(12);
                    .fontFn(20,line-height);
                }
                .honor{
                    width: 100%;
                    padding: 0 6%;
                    .honor-list{
                        .pic{
                            img{
                                width: 50vw;
                            }
                            &::before,
                            &::after{width: 10.8vw;height: 30vw;}
                        } 
                        p{width: 100%;.fontFn(14);}
                        .swiper-container{padding-bottom: 8vw !important;}
                        @{deep} .swiper-pagination-bullet-active{background-color: #666}
                    }
                }

                .process{
                    .time-line{
                        ul{
                            padding-top: 30px;
                            padding-left: 0;
                            li{
                                width: 25vw;
                                strong{
                                    font-size: 20px;
                                    left:40%;
                                    top:-16px;
                                    &.actived{
                                        font-size: 30px;
                                        left:40%;
                                        top:-24px;
                                    }
                                }
                            }
                        }
                    }
                    .process-content{
                        width: 100%;
                        padding: 0 6%;
                        flex-direction: column-reverse;
                        .lf{width: 100%;height: auto}
                        .rf{
                            width: 100%;
                            height: auto;
                            h1{.fontFn(18);}
                            p{.fontFn(14);.fontFn(20,line-height)}
                            .point{
                                .fontFn(8,width);
                                .fontFn(8,height);
                            }
                        }
                    }
                }

                .college,
                .table-list{
                    &>.rf{
                        width: 100%;
                        .btns{
                            button{
                                .fontFn(14);
                                .fontFn(96,width);
                                .fontFn(36,height);
                                .fontFn(36,line-height);
                            }
                        }

                    }
                    .list{
                        width: 100%;
                        .el-table{
                            display: none;
                        }
                    }
                }

                .college{
                    padding: 0 6%;
                    &>.lf{
                        display: none
                    }
                    &>.rf{
                        .btns{
                            button{

                            }
                        }
                    }
                }

                .table-list{
                    padding: 0 6%;
                    &>.lf{
                        display: none
                    }
                    &>.rf{
                        .btns{
                            button{

                            }
                        }
                    }
                    .list{
                        margin-top: 0;
                    }
                }
            }
        }
    }
</style>